<template>
  <div class="relative">
    <LayoutsHeader/>
    <slot/>
    <LayoutsFooter/>
    <LayoutsMobToolbar/>
    <Cookie/>
    <AnalyticsYandex />
    <UiScrollTop />
  </div>
</template>