<script setup>
    const clickTop = () => {
        if( import.meta.client ){
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        return
    }
    const visible = ref( false )
    if( import.meta.client ){
        window.onscroll = function() {
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                visible.value = true
            } else {
                visible.value = false
            }
        };
    }
</script>
<template>
    <ClientOnly>
        <Teleport to="body">
            <button @click="clickTop" v-show="visible" class="z-50 bg-brand-primary fixed bottom-3 left-3 w-12 h-12 rounded-full flex justify-center items-center text-2xl text-white cursor-pointer">
                ↑
            </button>
        </Teleport>
    </ClientOnly>
</template>