<script setup>
    const menu = ref(false);
    const app_config = useAppConfig()
</script>

<template>
    <header class="flex items-center container justify-between bg-brand-bright md:bg-white md:py-5 lg:py-0 py-3">
        <NuxtLink to="/">
            <img src="/logo.svg" class="w-28 md:w-44" alt="logo" />
        </NuxtLink>
        <nav>
            <ul class="items-center gap-10 hidden lg:flex">
                <li class="">
                    <NuxtLink to="/news" class="text-base font-normal text-black inline-block py-6"
                        active-class="active-link">Новости</NuxtLink>
                </li>
                <li class="">
                    <NuxtLink to="/articles" class="text-base font-normal text-black inline-block py-6"
                        active-class="active-link">Статьи</NuxtLink>
                </li>
                <li class="">
                    <NuxtLink to="/catalog" class="text-base font-normal text-black inline-block py-6"
                        active-class="active-link">Каталог</NuxtLink>
                </li>
                <li class="">
                    <NuxtLink to="/favorites" class="text-base font-normal text-black inline-block py-6"
                        active-class="active-link">Избранное</NuxtLink>
                </li>
                <li class="">
                    <NuxtLink to="/contacts" class="text-base font-normal text-black inline-block py-6"
                        active-class="active-link">Контакты</NuxtLink>
                </li>
            </ul>
        </nav>
        <div class="flex items-start flex-col">
            <a :href="app_config.contacts.phone_link" class="text-brand-blue sm:text-xl text-base hidden lg:block font-bold">
                {{ app_config.contacts.phone }}
            </a>
            <div class="md:flex hidden items-center gap-2 justify-start py-0 px-2 w-fit">
                <svg width="10px" height="10px" viewBox="0 0 23 23" id="meteor-icon-kit__solid-location-arrow" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="" stroke-width="0"></g><g id="" stroke-linecap="round" stroke-linejoin="round"></g><g id=""><path fill-rule="evenodd" clip-rule="evenodd" d="M0.755196 8.8346L21.7285 -0.038704C21.9775 -0.144081 22.2587 -0.144081 22.5077 -0.038704C23.0164 0.17649 23.2543 0.76327 23.0391 1.2719L14.1658 22.2452C14.0434 22.5345 13.7921 22.7495 13.4873 22.8257C12.9515 22.9596 12.4086 22.6339 12.2747 22.0981L10.0002 13.0002L0.9023 10.7257C0.597527 10.6495 0.346275 10.4345 0.223868 10.1452C0.0086761 9.6366 0.246559 9.0498 0.755196 8.8346z" fill="#000000"></path></g></svg>
                <span class="text-xs text-black">{{ app_config.city.im }}</span>
            </div>
        </div>
       
        <div class="flex items-center gap-5 lg:hidden">
            
            <a :href="app_config.contacts.phone_link" class="text-brand-green text-sm">{{ app_config.contacts.phone }}</a>

            <NuxtLink to="/favorites">
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.13317 6.7179L0.844199 7.69835L0.732811 7.72273C0.564191 7.77086 0.410472 7.86626 0.287351 7.99917C0.164231 8.13208 0.0761199 8.29774 0.032017 8.47924C-0.012086 8.66074 -0.010601 8.85158 0.0363201 9.03226C0.0832413 9.21295 0.173917 9.377 0.299089 9.50768L4.85514 14.2764L3.78069 21.0123L3.76788 21.1289C3.75756 21.3164 3.79377 21.5036 3.87281 21.6711C3.95185 21.8386 4.07088 21.9806 4.2177 22.0824C4.36453 22.1842 4.53388 22.2422 4.70841 22.2505C4.88295 22.2587 5.05639 22.217 5.21099 22.1295L10.8356 18.9497L16.4473 22.1295L16.5459 22.1783C16.7086 22.2472 16.8855 22.2683 17.0583 22.2395C17.2311 22.2107 17.3936 22.1329 17.5293 22.0143C17.6649 21.8956 17.7688 21.7402 17.8302 21.5642C17.8916 21.3881 17.9083 21.1976 17.8786 21.0123L16.8032 14.2764L21.3612 9.50662L21.4381 9.41652C21.5479 9.27106 21.6199 9.0969 21.6468 8.91177C21.6737 8.72664 21.6544 8.53717 21.591 8.36265C21.5276 8.18813 21.4223 8.03481 21.2858 7.9183C21.1493 7.8018 20.9866 7.72627 20.8141 7.69941L14.5252 6.7179L11.7139 0.591417C11.6325 0.413913 11.5066 0.26444 11.3503 0.159918C11.194 0.0553963 11.0137 0 10.8297 0C10.6456 0 10.4653 0.0553963 10.309 0.159918C10.1527 0.26444 10.0268 0.413913 9.94546 0.591417L7.13317 6.7179Z"
                        fill="#53B3F8" />
                </svg>

            </NuxtLink>
            <img src="/public/img/menu.svg" @click="menu = !menu" alt="menu" class="mr-2" />
        </div>

        <div class="mobile_menu block md:hidden bg-white border-r pl-3 fixed w-4/5 h-screen z-[60] top-0 left-0"
            :class="menu ? 'translate-x-0' : '-translate-x-full'">
            <div class="flex justify-between items-center pr-3 py-6">
                <NuxtLink @click="menu = false" to="/" class="w-2/3"><img src="/logo.svg" class="f-full"
                        alt="logo" />
                </NuxtLink>

                <button class="close" @click="menu = false">
                    <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L1 13" stroke="#8F8F8F" stroke-opacity="0.79" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M1 1L13 13" stroke="#8F8F8F" stroke-opacity="0.79" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
            </div>
            <nav class="mt-6 pl-4">
                <ul class="flex flex-col gap-5">
                    <li @click="menu = false">
                        <NuxtLink to="/prodaja" class="font-medium text-brand-grey" active-class="active-link">Продажа
                        </NuxtLink>
                    </li>
                    <li @click="menu = false">
                        <NuxtLink to="/arenda" class="font-medium text-brand-grey" active-class="active-link">Аренда
                        </NuxtLink>
                    </li>
                    <li @click="menu = false">
                        <NuxtLink to="/catalog" class="font-medium text-brand-grey" active-class="active-link">Каталог
                        </NuxtLink>
                    </li>
                    <li @click="menu = false">
                        <NuxtLink to="/favorites" class="font-medium text-brand-grey" active-class="active-link">Избранное
                        </NuxtLink>
                    </li>
                    <li @click="menu = false">
                        <NuxtLink to="/contacts" class="font-medium text-brand-grey" active-class="active-link">Контакты
                        </NuxtLink>
                    </li>
                </ul>
            </nav>

        </div>
    </header>
</template>

<style>
.active-link {
    position: relative;
    color: #00b6f4;
}

.active-link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #00b6f4;
    bottom: -1px;
}


nav>ul>li>a:hover {
    position: relative;
    color: #00b6f4;
}

nav>ul>li>a:hover:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #00b6f4;
    bottom: -1px;
}

.mobile_menu {
    transition: all 0.6s ease;
}
</style>
